@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap');
@font-face {
  font-family: Johnnie-Regular-Book;
  src: url("./assets/fonts/JohnnieWalkerSerifWeb-Book.woff") format("woff");
}
:root{
  --primary-color:purple;
  --background-color:#f1f1f1;
}

@font-face {
  font-family: Johnnie-Regular;
  font-weight: bold;
  src: url("./assets/fonts/JWJohnnie-Regular.otf") format("opentype");
}

.App {
  text-align: center;
  background-image: var(--background-image);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
body,html{
  height:100%;
  width:100%;
  overflow-x: hidden;
}
*{
  margin:0;
  padding:0;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  font-family: 'Open Sans Condensed','Johnnie-Regular','Helvetica 65 Medium', sans-serif;
  /* font-stretch: condensed; */
  // text-transform: uppercase;
  color:var(--primary-color);
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: initial;
}
h1{
  font-size: 3.5em;
  line-height: normal;
}
.font-4x{
  font-size: 8em;
}
.font-2x{
  font-size: 2em;
}
.font-1x{
  font-size: 1.15em;
}
.color-primary{
  color:var(--primary-color);
}
iframe {
  display: none;
}
body{
  background:var(--background-color);
}

.logo {

  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
p{
  font-family: 'Johnnie-Regular-Book', serif;
  margin-block-start: 0;
  margin-block-end: 0;
}

.App-header {
  position: static;
  top:0;
  width:100%;
  left:0;
  display:flex;
  padding:30px 0 15px;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-1{
  flex:1;
}

.center-page{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.center-page img.price{
  height: 15em;
  margin-top: 15px;
}
.color-black{
  color:#222;
}
.qr-code{
  margin-top:20px
}
p{
  /* margin-top:20px */
}
.card{
  background: #fff;
  padding:30px;
  box-shadow: 0 0 10px -5px #22222244;
  border-radius: 5px;
}
a.highlight{
  color:var(--primary-color);

  &:hover{
    text-decoration: underline; 
  }
}
.page-container{
  display: flex;
  flex-direction: column;
}
.sub-container{
  display: flex;

  @media screen and (max-width: 768px) {
  flex-direction: column;
  }
}
.side-container {
  background: #f5f5f5;
  flex:1;
  padding: 20px;
  min-height:100vh;
}
.color-picker{
  padding: 10px;
}
.admin-header {
  background: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  position: sticky;
  height: 60px;
  padding: 20px;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}
 .printable {
  display: none;
}
@media print {
 header, #print-hide,.ant-menu{
      display: none !important;
  }
  body{
    width: 100%;
    height: 100%;
  }
  body .printable {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }
  body .printable *{
    display: block !important;
  }
}  